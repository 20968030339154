import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/line-coupon.jpg';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="LINEクーポンを配布して来店を促進する"
		  desc="このサンプルはLINEクーポンを配布する方法です。LINEの友達であれば参加できるインスタントウィンを簡単に実施できます。ブロック解除を抑制しエンゲージメントを深める施策を開催できます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/line/instant-coupon'}
		  type={'Article'}
		  shortName="LINEクーポンを配布して来店を促進する"
		  createdAt="2021-11-30"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="LINEクーポンを配布して来店を促進する"
		homePageText="Home"
		homePageUrl="/"
		activePageText="LINEクーポンを配布して来店を促進する"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/line',
		  name: 'LINEキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"LINEクーポンを配布して来店を促進する"}
		description={<>このサンプルはLINEでインスタントウィンを行う方法です。<br/>
		LINEの公式アカウントの管理画面から、LINEクーポンを発行してLINEクーポンを賞品として提供します。この方法は簡単に実施できユーザーを店舗に集客するきっかけを与える素晴らしい方法です。<br/>
		Twitterのリツイート・いいねなど拡散性の高い応募方法を使用して、新規顧客を獲得することもできます。
		</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS, Constants.CAMPAIGN_EFFECTS.VERIFIED_BADGE, Constants.CAMPAIGN_EFFECTS.RETENTION
		  , Constants.CAMPAIGN_EFFECTS.INCREASED_VISIT_STORE, Constants.CAMPAIGN_EFFECTS.GET_FOLLOWERS, Constants.CAMPAIGN_EFFECTS.GET_HIGH_SPREAD]}
		plan={'ベーシックプラン以上'}
		promotionId="6352fb4c66037141aa"
		platformExample={
		  <PlatformExample
			title="その他のLINEキャンペーン"
			types={['lineReceipt', 'lineReferral', 'twitterToLine','lineInstantWin']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples